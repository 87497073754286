.cardContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  gap: 20px;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 35px;
  padding: 20px;
  background-color: #1e1e1e;
  border-radius: 11px;
  box-shadow: 0 5px 11px rgba(0, 0, 0, 0.1);
  width: 350px;
  text-align: center;
}

.card h3 {
  font-size: 27px;
  font-weight: bold;
}

.card p {
  font-size: 17px;
}

.websiteBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.websiteBtn button {
  align-self: center;
  width: 300px;
}

.errorMessage {
  color: #ff3a58;
}
