.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  gap: 20px;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 35px;
}

.cardName {
  font-size: 27px;
  font-weight: bold;
}

.modalActions {
  display: flex;
  gap: 15px;
  justify-content: space-between;
}

.buttonDanger {
  background-color: red;
  color: white;
  border: none;
  border-radius: 17px;
  padding: 0 15px;
}

.buttonDanger:hover {
  background-color: darkred;
}
