.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  width: 100%;
  box-sizing: border-box;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  z-index: 1000;
}

.hidden {
  transform: translateY(-100%);
  opacity: 0;
}

.container button {
  font-size: 17px;
  padding: 7px 15px;
  min-width: 100px;
}

.logoButtons {
  display: flex;
  align-items: center;
  gap: 30px;
}

.logoContainer {
  display: flex;
  justify-content: center;
  padding: 10px;
  height: 70px;
}

.buttons {
  display: flex;
  gap: 5px;
}

.loginButtons {
  display: flex;
  gap: 5px;
}

.menuIcon {
  cursor: pointer;
}

.fullscreenMenu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  z-index: 2000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.menuContent {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.menuButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;
  position: fixed;
  top: 0;
  width: 100%;
  box-sizing: border-box;
}

.closeMenuButton {
  background: none;
  border: none;
  color: white;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

@media (max-width: 425px) {
  .logoContainer {
    width: 170px;
  }
}
