.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  gap: 20px;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 35px;
}

.modalBtn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalBtn button {
  align-self: center;
  width: 300px;
}
