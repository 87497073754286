.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  margin: 25px 0;
}

.currencyContainer {
  display: flex;
  align-self: start;
}

.originalCurrency {
  font-size: 57px;
}

.amount {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.targetCurrency {
  font-size: 21px;
  color: rgba(255, 255, 255, 0.5);
}

.actionButton {
  display: flex;
  margin-bottom: 25px;
  gap: 50px;
}

.transactionsContainer {
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  gap: 25px;
}

.dateHeader {
  font-size: 21px;
  font-weight: bold;
  color: #ffffff;
  text-align: right;
  margin-bottom: 10px;
}

.transactions {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
