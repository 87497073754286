.container {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.balance {
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin-top: 20px;
  gap: 35px;
}

.actionButton {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 350px;
}
