.container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.label {
  font-size: 17px;
  font-weight: normal;
  color: white;
  margin-bottom: 10px;
}

.labelButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 11px;
  min-height: 57px;
  gap: 20px;
  transition: background-color 0.3s ease;
}

.labelButton.withBackground {
  background-color: #1e1e1e;
}

.labelButton.noBackground {
  background-color: transparent;
}

.labelButton.disabled {
  opacity: 0.5;
  cursor: auto;
}

.labelButton.inactive {
  opacity: 0.7;
}

.labelButton.small {
  padding: 5px 10px;
  min-height: 40px;
}

.content {
  display: flex;
  align-items: center;
  font-size: 27px;
  font-weight: normal;
  gap: 15px;
}

.content.small {
  font-size: 16px;
}

.icon {
  width: 37px;
  height: 37px;
  border-radius: 50%;
}

.labelButton.small .icon {
  width: 24px;
  height: 24px;
}

.errorMessage {
  font-size: 14px;
  color: #ff3a58;
  min-width: 100px;
}
