.container {
  display: flex;
  flex-direction: column;
}

.websiteName {
  font-size: 50px;
  align-self: center;
}

.websiteURL {
  font-size: 21px;
  align-self: center;
}

.apiKey {
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
}

.apiKeyText {
  font-size: 21px;
}
