.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 50px;
}

.firstContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: clamp(2.3125rem, 1.1875rem + 5.625vw, 6.25rem);
  font-weight: bold;
  height: 100vh;
  align-items: center;
}

.secondContainer {
  display: flex;
  justify-content: space-around;
  height: 100vh;
  align-items: center;
  gap: 25px;
}

.secondContent {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: clamp(1.0625rem, 0.1339rem + 1.9345vw, 1.875rem);
  width: 300px;
  height: 500px;
  border: 1px white solid;
  border-radius: 27px;
  padding: 25px;
  gap: 15px;
}

.secondText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 850px;
  height: 50%;
  gap: 25px;
}

.firstSecondText {
  font-size: clamp(2.3125rem, -0.0446rem + 4.9107vw, 4.375rem);
  font-weight: bold;
}

.secondSecondText {
  font-size: clamp(1.0625rem, 0.1339rem + 1.9345vw, 1.875rem);
  font-weight: normal;
}

.thirdContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100vh;
  align-items: center;
}

.thirdText {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  gap: 25px;
}

.firstThirdText {
  font-size: clamp(2.3125rem, -0.0446rem + 4.9107vw, 4.375rem);
  font-weight: bold;
}

.secondThirdText {
  font-size: clamp(1.0625rem, 0.1339rem + 1.9345vw, 1.875rem);
  font-weight: normal;
}

.thirdContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 25px;
}

@media (max-width: 1024px) {
  .secondContent {
    display: none;
  }
  .thirdContent {
    display: none;
  }
}
