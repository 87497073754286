.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  color: white;
  font-size: 21px;
  margin-bottom: 10px;
  align-self: flex-start;
}

.otpContainer {
  display: flex;
  gap: 15px;
}

.otpInput {
  width: 65px;
  height: 70px;
  font-size: 27px;
  text-align: center;
  border: 2px solid white;
  border-radius: 15px;
  background-color: white;
  color: black;
}

.otpInput:focus {
  outline: none;
  border-color: black;
}

.error {
  border-color: #ff4c4c;
}

.errorAndSecondLabel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  width: 100%;
}

.errorMessage {
  color: #ff4c4c;
  font-size: 14px;
  margin-right: auto;
}

.secondLabelButton {
  background: none;
  border: none;
  color: white;
  font-size: 17px;
  cursor: pointer;
  margin-left: auto;
}

.disabledButton {
  color: rgba(255, 255, 255, 0.5);
  cursor: auto;
}
