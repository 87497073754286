.walletList {
  text-align: center;
  padding: 20px;
}

.walletList h2 {
  margin-bottom: 20px;
}

.walletList ul {
  list-style-type: none;
  padding: 0;
}

.walletList li {
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ddd;
  cursor: pointer;
  border-radius: 5px;
}

.walletList li:hover {
  background-color: #f0f0f0;
}
