.container {
  display: flex;
  flex-direction: column;
  padding: 0px 100px;
  gap: 25px;
}

.pageName {
  font-size: 70px;
  align-self: center;
}

.fromContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #070707;
  padding: 30px;
  border-radius: 17px;
}

.toContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #070707;
  padding: 30px;
  border-radius: 17px;
}

.convertedAmount {
  font-size: 27px;
}

.exchangeRate {
  align-self: flex-end;
}

.swapBtn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.swapBtn button {
  align-self: center;
  width: 300px;
}

.modalLabelButton {
  margin-bottom: 10px;
}
