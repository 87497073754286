.container {
  display: flex;
  flex-direction: column;
  padding: 0px 100px;
  margin-top: 10px;
  gap: 25px;
}

.pageName {
  font-size: 50px;
  align-self: center;
}

.balance {
  font-size: 70px;
  align-self: center;
}

.networkName {
  font-size: 37px;
  align-self: center;
}

.currencyList {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.invoiceBtn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.invoiceBtn button {
  align-self: center;
  width: 300px;
}
