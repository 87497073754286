.clickableCard {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #121212;
  padding: 10px;
  border-radius: 11px;
  font-size: 15px;
  font-weight: bold;
  min-width: 188px;
  cursor: pointer;
  gap: 15px;
}

.logo {
  width: 37px;
  height: 37px;
  border-radius: 50%;
}

.text {
  font-size: 17px;
}
