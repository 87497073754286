.searchBar {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  background-color: #1e1e1e;
  border-radius: 21px;
}

.searchIcon {
  color: white;
  font-size: 20px;
  margin-right: 10px;
}

.searchInput {
  flex: 1;
  background-color: transparent;
  border: none;
  outline: none;
  color: white;
  font-size: 16px;
  font-weight: normal;
}

.searchInput::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.searchInput:focus {
  border: none;
  outline: none;
}
