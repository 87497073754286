.button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
  color: white;
  font-size: 27px;
  font-weight: normal;
  gap: 15px;
  transition: color 0.3s ease;
}

.iconContainer {
  width: 58px;
  height: 58px;
  background-color: #1e1e1e;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  font-size: 27px;
  color: white;
  width: 27px;
  height: 27px;
}

.disabled {
  opacity: 0.5;
  cursor: auto;
}
