.container {
  display: flex;
  flex-direction: column;
  padding: 0px 100px;
  gap: 25px;
}

.pageName {
  font-size: 70px;
  align-self: center;
}

.sendBtn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sendBtn button {
  align-self: center;
  width: 300px;
}
