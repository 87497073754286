.qrCodeContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 15px;
  border-radius: 17px;
  max-width: 260px;
  margin: 0 auto;
}
