.transactionsContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  gap: 25px;
}
.filter {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.dateHeader {
  font-size: 21px;
  font-weight: bold;
  color: #ffffff;
  text-align: right;
  margin-bottom: 10px;
}

.transactions {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.settingsButton {
  position: relative;
}

.modalContent {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.modalRow {
  display: flex;
  gap: 20px;
}

.field {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.field label {
  font-size: 16px;
  margin-bottom: 8px;
  color: #ffffff;
}

.select {
  padding: 8px;
  background-color: #1e1e1e;
  color: #ffffff;
  border: 1px solid #3a3a3a;
  border-radius: 4px;
}

.transactionTypes {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.checkbox {
  display: flex;
  align-items: center;
  gap: 5px;
}

.checkbox input[type="checkbox"] {
  width: 18px;
  height: 18px;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.buttons Button {
  width: 100px;
}
