.statusBadge {
  padding: 5px 10px;
  border-radius: 11px;
  color: white;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  display: inline-block;
}

.completed {
  background-color: #22ab94;
}

.pending {
  background-color: #ffa800;
}

.failed {
  background-color: #ff3a58;
}

.default {
  background-color: #555555;
}
