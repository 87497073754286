.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 15%;
  height: 100vh;
  background-color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  gap: 50px;
}

.logoContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 10px;
  height: 100px;
}

.navLinks {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 150px;
  gap: 35px;
}

.navLink {
  font-size: 27px;
  color: #7e7e7e;
  text-decoration: none;
  font-weight: normal;
  text-align: center;
}

.navLink.active {
  color: white;
}

.userCard {
  margin-top: 35px;
}
