.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #121212;
  border-radius: 11px;
  box-shadow: 0 5px 11px rgba(0, 0, 0, 0.1);
  padding: 20px;
  box-sizing: border-box;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.disabled:hover {
  transform: none;
  box-shadow: 0 5px 11px rgba(0, 0, 0, 0.1);
}

.inactive {
  opacity: 0.7;
  cursor: pointer;
}

.inactive:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}
