.container {
  display: flex;
  flex-direction: column;
}

.storeName {
  font-size: 50px;
  align-self: center;
}

.storeAddress {
  font-size: 21px;
  align-self: center;
}
