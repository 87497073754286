.container {
  display: flex;
  flex-direction: column;
  padding: 0px 100px;
  margin-top: 10px;
  gap: 25px;
}

.currentWallet {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 15px;
}

.currentWalletText {
  font-size: 37px;
}
