.cardContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  gap: 20px;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 35px;
}

.cardName {
  font-size: 27px;
  font-weight: bold;
}

.cardText {
  font-size: 17px;
}

.offlineStoreBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.offlineStoreBtn button {
  align-self: center;
  width: 300px;
}
