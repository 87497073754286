.timerContainer {
  display: flex;
  align-items: center;
  border-radius: 55px;
  padding: 15px;
  gap: 10px;
}

.timerCircle {
  border: 2px solid #fff;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.timerText {
  font-size: 1.5em;
  color: #fff;
  font-family: "Poppins", sans-serif;
}

.expireLabel {
  font-size: 2em;
  color: #fff;
  font-family: "Poppins", sans-serif;
}

@media (max-width: 1024px) {
  .timerContainer {
    padding: 7px;
  }
  .expireLabel {
    font-size: 1.5em;
  }
}
