.logoContainer {
  position: absolute;
  top: 15px;
  left: 35px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
  margin-top: 50px;
  gap: 15px;
}

.invoiceName {
  font-size: 57px;
}

.networkName {
  font-size: 37px;
}

.typedText {
  font-size: 100px;
}
