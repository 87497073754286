.currency {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 25px;
}

.currencyNoSelected {
  opacity: 0.5;
}

.currencyLogo {
  width: 58px;
  height: 58px;
}

.currencyContainerSimple {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.currencyCodeSimple {
  font-size: 21px;
  font-weight: bold;
  color: white;
}

.currencyNameSimple {
  font-size: 17px;
  font-weight: normal;
  color: rgba(255, 255, 255, 0.5);
}

.currencyContainer {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
}

.currencyName {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.currencyTitle {
  font-size: 21px;
  font-weight: bold;
  color: white;
}

.currencyExchangeRate {
  display: flex;
  gap: 10px;
}

.exchangeRate {
  font-size: 17px;
  font-weight: normal;
  color: rgba(255, 255, 255, 0.5);
}

.percentChangePositive {
  font-size: 17px;
  font-weight: normal;
  color: #6cda9b;
}

.percentChangeNegative {
  font-size: 17px;
  font-weight: normal;
  color: #ff3a58;
}

.currencyBalance {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: right;
}

.balance {
  font-size: 21px;
  font-weight: normal;
  color: white;
}

.convertedBalance {
  font-size: 17px;
  font-weight: normal;
  color: rgba(255, 255, 255, 0.5);
}

.currencyContainerCombined {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
}

.currencyLeft {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.currencyRight {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: right;
}
