.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 50px;
}

.firstContainer {
  display: flex;
  justify-content: space-around;
  min-height: 100vh;
  align-items: center;
  gap: 15px;
}

.firstText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: clamp(2.3125rem, 1.1875rem + 5.625vw, 6.25rem);
  font-weight: bold;
  width: 800px;
}

.typedText {
  font-size: clamp(2.3125rem, 1.1875rem + 5.625vw, 6.25rem);
  font-weight: bold;
}

.firstContent {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 350px;
  height: 550px;
  border: 1px white solid;
  border-radius: 27px;
  padding: 25px;
  box-sizing: border-box;
  margin-top: 50px;
}

.firstContent img {
  width: 50px;
  height: 50px;
}

.firstContent span {
  font-size: clamp(0.5rem, 0.4107rem + 0.4464vw, 0.8125rem);
}

.firstContentTextContainer {
  display: flex;
  flex-direction: column;
}

.firstContentText {
  font-size: clamp(1.0625rem, 0.8839rem + 0.8929vw, 1.6875rem);
}

.secondContentText {
  font-size: clamp(2.3125rem, 2.0804rem + 1.1607vw, 3.125rem);
  margin-bottom: 50px;
}

.firstContentCurrencyType {
  font-size: clamp(0.6875rem, 0.5089rem + 0.8929vw, 1.3125rem);
  font-weight: bold;
}

.nftContainer {
  display: flex;
  flex-direction: column;
}

.nftContainer img {
  border-radius: 17px;
  width: 100%;
  height: auto;
}

.nftTextContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 50px;
}

.nftNameContainer {
  display: flex;
  justify-content: space-between;
}

.nftName {
  font-size: clamp(1.0625rem, 0.8304rem + 1.1607vw, 1.875rem);
  font-weight: bold;
}

.nftNumber {
  font-size: clamp(1.0625rem, 0.8304rem + 1.1607vw, 1.875rem);
  font-weight: bold;
  align-self: center;
}

.nftSecondTextContainer {
  display: flex;
  justify-content: space-between;
}
.nftText {
  display: flex;
  flex-direction: column;
  font-size: clamp(0.6875rem, 0.5982rem + 0.4464vw, 1rem);
}
.nftSecondText {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: clamp(0.6875rem, 0.5982rem + 0.4464vw, 1rem);
}

.secondContainer {
  display: flex;
  justify-content: space-around;
  min-height: 100vh;
  align-items: center;
  gap: 15px;
}

.secondContent {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 350px;
  height: 550px;
  border: 1px white solid;
  border-radius: 27px;
  padding: 25px;
  box-sizing: border-box;
  margin-top: 50px;
}

.secondContentAddress {
  font-size: 10px;
}

.secondTextLogo {
  width: 300px;
  height: 25px;
  align-self: start;
}

.secondText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: normal;
  width: 800px;
}

.firstSecondText {
  font-size: clamp(2.3125rem, 1.7232rem + 2.9464vw, 4.375rem);
  font-weight: bold;
  margin-bottom: 25px;
}

.secondSecondText {
  font-size: clamp(1.0625rem, 0.1339rem + 1.9345vw, 1.875rem);
  font-weight: normal;
  margin-bottom: 25px;
}

.thirdContainer {
  display: flex;
  justify-content: space-around;
  min-height: 100vh;
  align-items: center;
  gap: 15px;
}

.thirdText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 800px;
}

.firstThirdText {
  font-size: clamp(2.3125rem, 1.1875rem + 5.625vw, 6.25rem);
  font-weight: bold;
}

.secondThirdText {
  font-size: clamp(1.0625rem, 0.1339rem + 1.9345vw, 1.875rem);
}

.thirdContent {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 350px;
  height: 550px;
  border: 1px white solid;
  border-radius: 27px;
  padding: 25px;
  box-sizing: border-box;
  margin-top: 50px;
}

.swapContentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
}

.swapContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1e1e1e;
  border-radius: 17px;
  padding: 10px 25px;
  width: 80%;
}

.swapContent img {
  width: 43px;
  height: 43px;
}

.swapContent span {
  font-size: 17px;
}

.firstSwapContentAmountContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.firstSwapContentAmount {
  font-size: 23px;
  font-weight: bold;
}

.firstSwapContentSecondAmount {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
}

.secondSwapContentAmount {
  font-size: 27px;
  font-weight: bold;
}

.swapContentButton {
  display: flex;
  justify-content: center;
  background: linear-gradient(45deg, #85ffc4, #5cc6ff, #bc85ff);
  border-radius: 37px;
}

.fourthContainer {
  display: flex;
  justify-content: space-around;
  min-height: 100vh;
  align-items: center;
  gap: 15px;
}

.fourthContent {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 350px;
  height: 550px;
  border: 1px white solid;
  border-radius: 27px;
  padding: 25px;
  box-sizing: border-box;
  margin-top: 50px;
}

.fourthContentAmountContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fourthContentAmount {
  font-size: clamp(2.3125rem, 2.0804rem + 1.1607vw, 3.125rem);
}

.fourthContentCrypto {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.fourthContentCrypto img {
  width: 50px;
  height: 50px;
}

.fourthContentCrypto span {
  font-size: clamp(0.5rem, 0.4107rem + 0.4464vw, 0.8125rem);
}

.fourthTextLogo {
  width: 500px;
  height: 100px;
}

.fourthText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: clamp(1.0625rem, 0.1339rem + 1.9345vw, 1.875rem);
  width: 800px;
  gap: 25px;
}

.fourthText button {
  align-self: flex-end;
}

@media (max-width: 1100px) {
  .firstContent {
    min-width: 300px;
    min-height: 500px;
  }
  .firstContent img {
    width: 37px;
    height: 37px;
  }
  .nftContainer img {
    border-radius: 17px;
    width: 100%;
    height: auto;
  }
  .nftTextContainer {
    gap: 15px;
  }
  .secondText button {
    align-self: start;
  }
  .secondContent {
    min-width: 300px;
    min-height: 500px;
  }
  .thirdContent {
    min-width: 300px;
    min-height: 500px;
  }
  .fourthTextLogo {
    width: 300px;
  }
  .fourthContent {
    min-width: 300px;
    min-height: 500px;
  }
  .fourthContentCrypto img {
    width: 37px;
    height: 37px;
  }
}

@media (max-width: 768px) {
  .firstContainer {
    flex-direction: column;
    margin-top: 100px;
  }
  .firstText {
    align-items: center;
    width: auto;
  }
  .firstContent {
    width: 200px;
    height: 300px;
  }
  .secondContainer {
    flex-direction: column;
    margin-top: 200px;
  }
  .secondText {
    align-items: center;
    width: auto;
  }
  .secondTextLogo {
    align-self: center;
  }
  .secondText button {
    align-self: center;
  }
  .thirdContainer {
    flex-direction: column;
    margin-top: 200px;
  }
  .thirdText {
    align-items: center;
    width: auto;
  }
  .fourthContainer {
    flex-direction: column;
    margin-top: 100px;
  }
  .fourthText {
    align-items: center;
    width: auto;
  }
  .fourthText button {
    align-self: center;
  }
}
