.container {
  display: flex;
  flex-direction: column;
  padding: 0px 100px;
  margin-top: 50px;
  gap: 25px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  gap: 25px;
}

.invoiceBtn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.invoiceBtn button {
  align-self: center;
  width: 300px;
}

.modalLabelButton {
  margin-bottom: 10px;
}

.toggleBtn {
  display: flex;
  align-items: center;
  gap: 10px;
}
