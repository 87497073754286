.totalBalanceContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: white;
}

.label {
  font-size: 37px;
  font-weight: normal;
  margin-bottom: 10px;
}

.balance {
  font-size: 77px;
  font-weight: normal;
}
