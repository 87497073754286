.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 8px;
  max-width: 400px;
  margin: 0 auto;
}
