.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: hidden;
}

.modalContent {
  background-color: #070707;
  border-radius: 15px;
  padding: 20px 50px;
  margin: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.title {
  color: white;
  font-size: 24px;
  font-weight: bold;
  margin: 0;
}

.closeButton {
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

.body {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex-grow: 1;
}

.childBlock {
  margin-bottom: 15px;
}

.childBlock:last-child {
  margin-bottom: 0;
}

.firstBlock {
  margin-top: 25px;
  margin-bottom: 30px;
}
