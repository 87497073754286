.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 50px;
}

.firstContainer {
  display: flex;
  justify-content: space-around;
  height: 100vh;
  align-items: center;
}

.typedText {
  font-size: 100px;
  font-weight: bold;
}

.secondContainer {
  display: flex;
  justify-content: space-around;
  height: 100vh;
  align-items: center;
}

.secondText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 570px;
  height: 50%;
  gap: 25px;
}

.firstSecondText {
  font-size: 70px;
  font-weight: bold;
}

.secondSecondText {
  font-size: 50px;
  font-weight: normal;
  width: 1000px;
}

.secondContent {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 800px;
  height: 500px;
  border: 1px white solid;
  border-radius: 27px;
  padding: 25px;
}

.qrCodeContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 80%;
}

.qrCode {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 210px;
  height: 210px;
  border-radius: 17px;
  padding: 15px;
  margin-bottom: 15px;
}

.qrAmount {
  font-size: 50px;
}

.qrName {
  font-size: 50px;
}

.address {
  background-color: #121212;
  padding: 8px 15px;
  border-radius: 11px;
}

.timer {
  display: flex;
  justify-content: flex-end;
  margin: -32px;
}

.thirdContainer {
  display: flex;
  justify-content: space-around;
  height: 100vh;
  align-items: center;
}

.thirdText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 570px;
  height: 50%;
  gap: 25px;
}

.firstThirdText {
  font-size: 70px;
  font-weight: bold;
}

.secondThirdText {
  font-size: 50px;
  font-weight: normal;
}

.thirdContent {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 800px;
  height: 500px;
  border: 1px white solid;
  border-radius: 27px;
  padding: 25px;
}

.fourthContainer {
  display: flex;
  justify-content: space-around;
  height: 100vh;
  align-items: center;
}

.fourthText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 50%;
  width: 800px;
}

.fourthTextLogo {
  display: flex;
  width: 300px;
  height: 50px;
}

.firstFourthText {
  font-size: 50px;
  font-weight: bold;
  margin-bottom: 25px;
}

.secondFourthText {
  font-size: 30px;
  font-weight: normal;
  margin-bottom: 25px;
}

.fourthContent {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 300px;
  height: 500px;
  margin-top: 50px;
}

.fifthContainer {
  display: flex;
  justify-content: space-around;
  height: 100vh;
  align-items: center;
}

.fifthText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 570px;
  height: 50%;
}

.fifthTextLogo {
  display: flex;
  width: 300px;
  height: 25px;
}

.firstFifthText {
  font-size: 50px;
  font-weight: bold;
  margin-bottom: 25px;
}

.secondFifthText {
  font-size: 30px;
  font-weight: normal;
  margin-bottom: 25px;
}

.fifthContent {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 300px;
  height: 500px;
  border: 1px white solid;
  border-radius: 27px;
  padding: 25px;
  margin-top: 50px;
}

.sixthContainer {
  display: flex;
  justify-content: space-around;
  height: 100vh;
  align-items: center;
}

.sixthText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 570px;
  height: 50%;
}

.firstSixthText {
  font-size: 50px;
  font-weight: bold;
  margin-bottom: 25px;
}

.secondSixthText {
  font-size: 30px;
  font-weight: normal;
  margin-bottom: 25px;
}

.sixthContent {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 300px;
  height: 500px;
  border: 1px white solid;
  border-radius: 27px;
  padding: 25px;
  margin-top: 50px;
}

.sixthContentText {
  align-self: center;
  font-size: 30px;
}

.sixthContentButtons {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 400px;
}

.seventhContainer {
  display: flex;
  justify-content: space-around;
  height: 100vh;
  align-items: center;
}

.seventhText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 570px;
  height: 50%;
}

.firstSeventhText {
  font-size: 50px;
  font-weight: bold;
  margin-bottom: 25px;
}

.secondSeventhText {
  font-size: 30px;
  font-weight: normal;
  margin-bottom: 25px;
}

.seventhContent {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 900px;
  height: 500px;
  background-color: rgba(18, 18, 18, 0.5);
  border-radius: 27px;
  padding: 25px;
  margin-top: 50px;
}

.seventhContentText {
  align-self: center;
  font-size: 17px;
  font-weight: bold;
}
