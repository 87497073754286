.contentContainer {
  display: flex;
  flex-direction: column;
  padding: 0px 100px;
  margin-top: 10px;
  gap: 25px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.transferContainer {
  display: flex;
  flex-direction: column;
  padding: 0px 100px;
  margin-top: 10px;
  gap: 25px;
}

.pageName {
  font-size: 50px;
  align-self: center;
}

.transferBtn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.transferBtn button {
  align-self: center;
  width: 300px;
}

.modalLabelButton {
  margin-bottom: 10px;
}
