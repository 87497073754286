.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 35px;
  gap: 25px;
}

.networkName {
  font-size: 50px;
}
