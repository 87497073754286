.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 50px;
}

.firstContainer {
  display: flex;
  justify-content: space-around;
  height: 100vh;
  align-items: center;
}

.typedText {
  font-size: clamp(2.3125rem, 1.1875rem + 5.625vw, 6.25rem);
  font-weight: bold;
}
