.tabMenu {
  display: flex;
  margin: 25px 20px;
  gap: 35px;
}

.tabMenu button {
  background-color: transparent;
  color: rgba(255, 255, 255, 0.5);
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 37px;
  border: none;
  cursor: pointer;
}

.tabMenu button.activeTab {
  color: white;
  cursor: auto;
}
