.addressContainer {
  display: flex;
  align-items: center;
  background-color: #121212;
  padding: 10px;
  border-radius: 8px;
}

.addressText {
  flex-grow: 1;
  font-size: 21px;
  cursor: pointer;
}
