.transactionContainer {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  padding: 25px 25px;
  border-radius: 11px;
  background-color: #070707;
  align-items: center;
  cursor: pointer;
}

.swapTransactionContainer {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  padding: 25px 25px;
  border-radius: 11px;
  background-color: #070707;
  align-items: center;
  cursor: pointer;
}

.gridItem {
  font-size: 21px;
  font-weight: bold;
  color: white;
}

.time {
  justify-self: center;
  font-size: 21px;
  font-weight: bold;
  color: white;
}

.swapIcon {
  font-size: 50px;
  text-align: center;
}

.swapCurrency {
  display: flex;
  justify-content: space-between;
  grid-column: span 3;
}
