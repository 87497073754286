.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 50px;
}

.firstContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  align-items: center;
}

.typedText {
  font-size: clamp(2.3125rem, 1.1875rem + 5.625vw, 6.25rem);
  font-weight: bold;
  width: 1000px;
}

.secondContainer {
  display: flex;
  justify-content: space-around;
  height: 100vh;
  align-items: center;
}

.secondText {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 25px;
}

.firstSecondText {
  font-size: clamp(2.3125rem, -0.0446rem + 4.9107vw, 4.375rem);
  font-weight: bold;
}

.secondSecondText {
  font-size: clamp(1.0625rem, 0.6339rem + 0.8929vw, 1.4375rem);
  font-weight: normal;
}

.secondContent {
  width: 500px;
  height: 500px;
}

@media (max-width: 768px) {
  .typedText {
    width: auto;
  }
  .secondContent {
    display: none;
  }
}
