* {
  padding: 0;
  margin: 0;
  /* border: 1px solid aqua; */
}

/* Стилизация скроллбара для всего документа */
::-webkit-scrollbar {
  width: 8px; /* Ширина полосы прокрутки */
}

/* Стилизация дорожки скроллбара (часть, по которой движется "бегунок") */
::-webkit-scrollbar-track {
  background: none; /* Цвет фона дорожки */
}

/* Стилизация "бегунка" скроллбара */
::-webkit-scrollbar-thumb {
  background: #070707; /* Цвет фона бегунка */
  border-radius: 27px;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color: white;
}

code {
  font-family: "Poppins", sans-serif;
}

/* Сброс внешнего вида элемента и скрытие стрелок для всех браузеров */
input[type="number"] {
  -webkit-appearance: none; /* Для WebKit браузеров */
  -moz-appearance: textfield; /* Для Firefox */
  appearance: none; /* Стандартное свойство для всех браузеров */
}

/* Скрытие стрелок в WebKit браузерах */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
