.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pageName {
  color: white;
  font-size: 70px;
  font-weight: normal;
  text-align: center;
  margin: 50px 0;
}
