.button {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: none;
  border-radius: 27px;
  font-family: "Poppins", sans-serif;
  font-size: 21px;
  font-weight: normal;
  color: #ffffff;
  cursor: pointer;
  padding: 10px 18px;
  min-width: 150px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  gap: 25px;
}

.withBackground {
  background-color: #1e1e1e;
}

.noBackground {
  background-color: transparent;
}

.buttonDisabled {
  opacity: 0.5;
  cursor: auto;
}
