.container {
  display: flex;
  align-items: center;
  background-color: #1e1e1e;
  border-radius: 27px;
}

.modalContent {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.modalButtons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
