.container {
  display: flex;
  gap: 100px;
}

.icon {
  font-size: 70px;
  color: white;
  margin-bottom: 50px;
}

.text {
  font-size: 27px;
  color: white;
}
