.logoContainer {
  position: absolute;
  top: 15px;
  left: 35px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
  margin-top: 50px;
  gap: 15px;
}

.invoiceName {
  font-size: 70px;
}

.networkName {
  font-size: 37px;
}

.invoiceAmount {
  font-size: 57px;
}

.invoiceButton {
  display: flex;
  flex-direction: column;
  width: 300px;
}

.timerContainer {
  position: absolute;
  right: 25px;
  bottom: 30px;
}

.statusContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.typedText {
  font-size: 100px;
}
