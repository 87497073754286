.container {
  display: flex;
  flex-direction: column;
  width: 500px;
}

.container button {
  align-self: center;
  width: 300px;
}
