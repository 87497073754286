.inputContainer {
  display: flex;
  flex-direction: column;
  position: relative;
}

.inputLabel {
  font-size: 17px;
  font-weight: normal;
  color: white;
  margin-bottom: 10px;
}

.inputWrapper {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  background-color: #1e1e1e;
  border-radius: 11px;
  min-height: 37px;
}

.input {
  flex: 1;
  padding: 0px 20px;
  border: none;
  border-radius: 11px;
  font-size: 27px;
  font-weight: normal;
  color: white;
  background-color: transparent;
  outline: none;
  width: 100%;
}

.inputNoLeftContent {
  padding-left: 5px;
}

.input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.icon {
  width: 37px;
  height: 37px;
  border-radius: 50%;
}

.prefix {
  font-size: 27px;
  color: white;
}

.leftContent {
  display: flex;
  align-items: center;
  gap: 10px;
}

.rightContent {
  display: flex;
  align-items: center;
  gap: 5px;
}

.rightText {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.7);
}

.rightButton {
  padding: 5px 10px;
  border: none;
  background-color: transparent;
  color: white;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.rightButton:hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
}

.errorAndSecondaryLabel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  min-height: 20px;
}

.errorMessage {
  font-size: 14px;
  color: #ff3a58;
  min-width: 100px;
}

.secondaryLabel {
  font-size: 17px;
  color: white;
  font-weight: normal;
  cursor: pointer;
  margin-top: 10px;
  margin-left: auto;
  transition: opacity 0.3s ease;
}

.secondaryLabel:active {
  color: rgba(255, 255, 255, 0.7);
}

.secondaryLabelDisabled {
  cursor: default;
  color: rgba(255, 255, 255, 0.5);
  pointer-events: none;
}

.input:disabled {
  opacity: 0.5;
  cursor: auto;
}

.input:disabled + .inputLabel,
.input:disabled + .secondaryLabel {
  opacity: 0.5;
  cursor: auto;
  pointer-events: none;
}

.inputError {
  box-shadow: 0 0 0 1px #ff4c4c;
}
