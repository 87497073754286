.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 50px;
}

.firstContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  align-items: center;
}

.typedText {
  font-size: clamp(2.3125rem, 1.1875rem + 5.625vw, 6.25rem);
  font-weight: bold;
}

.secondContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 100vh;
  align-items: center;
}

.secondText {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: clamp(2.3125rem, 1.7232rem + 2.9464vw, 4.375rem);
  font-weight: normal;
}

.secondLightText {
  font-weight: lighter;
}

.secondContent {
  display: flex;
  justify-content: space-around;
  width: 100%;
  gap: 15px;
}

.logoContainer {
  width: 300px;
  height: 100px;
}

.secondContentBusinessContainer {
  display: flex;
  flex-direction: column;
}

.secondContentBusiness {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 850px;
  height: 550px;
  border: 1px white solid;
  border-radius: 27px;
  padding: 25px;
  box-sizing: border-box;
}

.balanceBusiness {
  font-size: clamp(2.3125rem, -0.0446rem + 4.9107vw, 4.375rem);
}

.currencyListBusiness {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 300px;
}

.currencyListBusiness span {
  font-size: clamp(0.6875rem, -0.0268rem + 1.4881vw, 1.3125rem);
}

.secondContentPersonalContainer {
  display: flex;
  flex-direction: column;
}

.secondContentPersonal {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 350px;
  height: 550px;
  border: 1px white solid;
  border-radius: 27px;
  padding: 25px;
  box-sizing: border-box;
}

.balancePersonal {
  font-size: clamp(2.3125rem, 1.3839rem + 1.9345vw, 3.125rem);
}

.currencyListPersonal {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 250px;
}

.currencyListPersonal img {
  width: 50px;
  height: 50px;
}

.currencyListPersonal span {
  font-size: clamp(0.5rem, 0.2143rem + 0.5952vw, 0.75rem);
}

.thirdContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 100vh;
  align-items: center;
  margin-top: 150px;
  gap: 25px;
}

.thirdTextContainer {
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  width: 100%;
  gap: 15px;
}

.thirdTextContainer button {
  width: 350px;
}

.thirdText {
  display: flex;
  flex-direction: column;
  font-size: clamp(2.3125rem, 2.0804rem + 1.1607vw, 3.125rem);
  width: 800px;
}

.thirdContent {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.thirdFirstContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 500px;
  height: 500px;
  border-radius: 27px;
  padding: 25px;
}

.thirdSecondContent {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 800px;
  height: 500px;
  border: 1px white solid;
  border-radius: 27px;
  padding: 25px;
}

.invoiceContent {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.qrCodeContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.qrCode {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 210px;
  height: 210px;
  border-radius: 17px;
  padding: 15px;
  margin-bottom: 15px;
}

.qrAmount {
  font-size: 50px;
}

.address {
  background-color: #121212;
  padding: 8px 15px;
  border-radius: 11px;
}

.timer {
  display: flex;
  justify-content: flex-end;
  margin: -15px;
}

.fourthContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  min-height: 100vh;
  margin-top: 150px;
}

.fourthTextContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 25px;
}

.fourthText {
  font-size: clamp(2.3125rem, 1.7232rem + 2.9464vw, 4.375rem);
}

.fourthSecondTextContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}

.fourthSecondText {
  font-size: clamp(1.0625rem, 0.8304rem + 1.1607vw, 1.875rem);
  width: 1000px;
}

.fourthSecondTextContainer button {
  width: 280px;
}

.fourthContent {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  height: 350px;
  pointer-events: none;
}

.fourthLogoContainer {
  width: 300px;
  height: 50px;
}

@media (max-width: 1330px) {
  .secondContentPersonalContainer {
    display: none;
  }
  .secondContentBusiness {
    width: 650px;
  }
  .currencyListBusiness img {
    width: 37px;
    height: 37px;
  }
  .currencyListPersonal img {
    width: 37px;
    height: 37px;
  }
  .thirdFirstContent {
    display: none;
  }
  .fourthContent img {
    width: 37px;
    height: 37px;
  }
}

@media (max-width: 1020px) {
  .fourthContent {
    display: none;
  }
}

@media (max-width: 768px) {
  .secondContentBusiness {
    width: 300px;
  }
  .thirdText {
    width: auto;
  }
  .thirdTextContainer {
    flex-direction: column;
    align-items: center;
  }
  .thirdSecondContent {
    width: 300px;
    align-items: center;
  }
  .qrCode {
    width: 170px;
    height: 170px;
    margin-top: 25px;
  }
  .address {
    display: none;
  }
  .timer {
    display: none;
  }
  .fourthSecondTextContainer {
    flex-direction: column;
  }
  .fourthSecondText {
    width: auto;
  }
}

@media (max-width: 425px) {
  .logoContainer {
    width: 200px;
  }
  .secondContentBusinessContainer {
    display: none;
  }
  .thirdTextContainer button {
    width: auto;
  }
  .fourthLogoContainer {
    width: auto;
  }
}
