.layout {
  display: flex;
}

.content {
  padding: 10px 55px;
  flex-grow: 1;
  margin-left: 15%;
  box-sizing: border-box;
}
